import SourceFlowText from "@sourceflow-uk/sourceflowtext";
import SourceFlowImage from "@sourceflow-uk/sourceflowimage";
import {
  Container,
  Nav,
  NavItem,
  Row,
  Col,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Collapse,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import styles from "./styles.module.scss";
import Link from "next/link";
import Logo from "/public/site-assets/svg/logo-white.svg";
// import PrideLogo from "/public/site-assets/svg/pride_logo.svg";
import MainNavigation from "../../.sourceflow/menu_navigations.json";
import SolutionsNavigation from "../../.sourceflow/solutions.json";
import SolutionsNavigationUS from "../../.sourceflow/solutions_us.json";
import MobileNavigations from "../../.sourceflow/mobile_navigations.json";
import React, { useState, useEffect, useRef } from "react";
import SearchIcon from "/public/site-assets/svg/black-magnifying-glass.svg";
import CloseMark from "/public/site-assets/svg/close-mark.svg";
import Telephone from "/public/site-assets/svg/telephone.svg";
import NewsTag from "../../.sourceflow/news_tag.json";
import News from "../../.sourceflow/news.json";
import Fuse from "fuse.js";
import { useRouter } from "next/router";

import UKFlag from "/public/site-assets/svg/uk-flag.svg";
import USFlag from "/public/site-assets/svg/us-flag.svg";
import DEFlag from "/public/site-assets/svg/germany-flag.svg";

export default function MainHeader({ global, pathPrefix, content }) {
  const router = useRouter();

  const dropdownRefs = {};
  const [isScrolled, setIsScrolled] = useState(false);

  const [isAboutDropdownOpen, setAboutIsDropdownOpen] = useState(false);
  const [isInsightsDropdownOpen, setInsightsIsDropdownOpen] = useState(false);
  const [isSolutionssDropdownOpen, setSolutionsIsDropdownOpen] =
    useState(false);
  const [isContractorsDropdownOpen, setContractorsIsDropdownOpen] =
    useState(false);
  const [isJobSeekersDropdownOpen, setJobSeekerssIsDropdownOpen] =
    useState(false);
  const [isClientsDropdownOpen, setClientsIsDropdownOpen] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };
  // Function to toggle a specific dropdown
  const toggleDropdown = (dropdownName) => {
    if (dropdownName === "About Us") {
      setAboutIsDropdownOpen(!isAboutDropdownOpen);
      setSolutionsIsDropdownOpen(false);
      setContractorsIsDropdownOpen(false);
      setJobSeekerssIsDropdownOpen(false);
      setClientsIsDropdownOpen(false);
      setInsightsIsDropdownOpen(false); // Close the other dropdown
    } else if (dropdownName === "Insights") {
      setInsightsIsDropdownOpen(!isInsightsDropdownOpen);
      setSolutionsIsDropdownOpen(false);
      setContractorsIsDropdownOpen(false);
      setJobSeekerssIsDropdownOpen(false);
      setClientsIsDropdownOpen(false);
      setAboutIsDropdownOpen(false); // Close the other dropdown
    } else if (dropdownName === "Solutions") {
      setSolutionsIsDropdownOpen(!isSolutionssDropdownOpen);
      setAboutIsDropdownOpen(false);
      setContractorsIsDropdownOpen(false);
      setJobSeekerssIsDropdownOpen(false);
      setClientsIsDropdownOpen(false);
      setInsightsIsDropdownOpen(false); // Close the other dropdown
    } else if (dropdownName === "Contractors") {
      setContractorsIsDropdownOpen(!isContractorsDropdownOpen);
      setAboutIsDropdownOpen(false);
      setSolutionsIsDropdownOpen(false);
      setJobSeekerssIsDropdownOpen(false);
      setClientsIsDropdownOpen(false);
      setInsightsIsDropdownOpen(false); // Close the other dropdown
    } else if (dropdownName === "Job Seekers") {
      setJobSeekerssIsDropdownOpen(!isJobSeekersDropdownOpen);
      setAboutIsDropdownOpen(false);
      setSolutionsIsDropdownOpen(false);
      setContractorsIsDropdownOpen(false);
      setClientsIsDropdownOpen(false);
      setInsightsIsDropdownOpen(false); // Close the other dropdown
    } else if (dropdownName === "Business Solutions") {
      setClientsIsDropdownOpen(!isClientsDropdownOpen);
      setJobSeekerssIsDropdownOpen(false);
      setAboutIsDropdownOpen(false);
      setSolutionsIsDropdownOpen(false);
      setContractorsIsDropdownOpen(false);
      setInsightsIsDropdownOpen(false); // Close the other dropdown
    }
    // Add more conditions for other dropdowns if needed
  };

  // Function to handle clicks outside of all dropdowns
  const handleClickOutside = (event) => {
    if (
      !event.target.closest(".dropdown-navigations") && // Check if clicked outside the dropdown-navigations
      !event.target.closest(".parent") // Check if clicked outside any dropdown button
    ) {
      setAboutIsDropdownOpen(false);
      setInsightsIsDropdownOpen(false);
      setSolutionsIsDropdownOpen(false);
      setContractorsIsDropdownOpen(false);
      setJobSeekerssIsDropdownOpen(false);
      setClientsIsDropdownOpen(false);
    }
  };

  // Function to close all dropdowns
  const closeAllDropdowns = () => {
    setAboutIsDropdownOpen(false);
    setInsightsIsDropdownOpen(false);
    setSolutionsIsDropdownOpen(false);
    setContractorsIsDropdownOpen(false);
    setJobSeekerssIsDropdownOpen(false);
    setClientsIsDropdownOpen(false);
    // Add more conditions for other dropdowns if needed
  };

  useEffect(() => {
    // Attach the event listener when any dropdown is open
    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const html = document.querySelector("html");
    if (html) {
      html.style.overflow = isOpen ? "hidden" : "auto";
    }

    const handleScroll = () => {
      const currentPosition = window.scrollY;
      if (currentPosition > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isOpen]);

  const AllInsights = News.category_values.filter(function (post) {
    return post.country?.en == (router.pathname.startsWith("/us") ? "United States" : "United Kingdom") || post.country?.en == "All";
  });

  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  // Create an instance of Fuse with your data
  const fuseOptions = {
    includeScore: true,
    keys: ["title.en"],
  };

  const fuse = new Fuse(AllInsights, fuseOptions);

  // Create a function to handle search
  const handleSearch = (query) => {
    const results = fuse.search(query);
    // setSearchResults(results.map((result) => result.title.en)); // Access 'title.en'
    setSearchResults(results);
  };

  // Add an event handler for the search input
  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    handleSearch(query);
  };

  const [open, setOpen] = useState("");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  // ReciteMe Script
  useEffect(() => {
    const enableReciteButton = document.getElementById("reciteEnable");

    if (enableReciteButton) {
      enableReciteButton.addEventListener("click", loadService);
    }

    return () => {
      if (enableReciteButton) {
        enableReciteButton.removeEventListener("click", loadService);
      }
    };
  }, []);

  return (
    <>
      <section
        className={`${styles.root} main-navigation ${isOpen ? "active" : ""}`}
      >
        <div className="reciteWrapper py-2 bg-white">
          <Container>
            <button
              className="recite-btn p-0"
              id="reciteEnable"
              aria-label="Accessibility and Languages"
            >
              Accessibility and Languages
            </button>
          </Container>
        </div>
        <Container className="position-relative mainWrapper">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              {/* <Link
                href={`${router.pathname.startsWith("/us") ? "/us/" : "/"}`}
              >
                <a className="logo me-md-5" aria-label="SRG" title="SRG">
                  <Logo />
                </a>
              </Link> */}
              <Link
                href={`${router.pathname.startsWith("/us") ? "/us/" : "/"}`}
              >
                <a className="logo me-md-4" aria-label="SRG" title="SRG">
                  <Logo width={120} height={120}/>
                </a>
              </Link>
              <Nav className="d-none d-lg-flex">
                {MainNavigation.category_values.map((post, index) => (
                  <NavItem key={index} className="parent mx-1">
                    <button
                      className={`border-0 bg-transparent dropdown ${
                        post.has_children?.en ? "has-children" : null
                      }`}
                      aria-label={`${post?.title.en}`}
                      onClick={() => toggleDropdown(post?.title.en)} // Pass the dropdown name
                      ref={(ref) => (dropdownRefs[post?.title.en] = ref)} // Use a separate ref for each dropdown
                    >
                      <strong>{post?.title.en}</strong>
                    </button>
                  </NavItem>
                ))}
              </Nav>
              <UncontrolledDropdown className="lang_select d-none d-lg-flex">
                <DropdownToggle aria-label="Language">
                  <span
                    className={
                      router.pathname.startsWith("/") &&
                      !router.pathname.startsWith("/us")
                        ? "active"
                        : ""
                    }
                  >
                    <UKFlag />
                  </span>
                  <span
                    className={
                      router.pathname.startsWith("/us") ? "active" : ""
                    }
                  >
                    <USFlag />
                  </span>
                  <span
                    className={
                      router.pathname.startsWith("/au") &&
                      !router.pathname.startsWith("/us")
                        ? "active"
                        : ""
                    }
                  >
                    <DEFlag />
                  </span>
                </DropdownToggle>

                <DropdownMenu aria-labelledby="dropdownMenuButton">
                  <DropdownItem
                    href="/"
                    title={`United Kingdom`}
                    aria-label={`United Kingdom`}
                    className={
                      router.pathname.startsWith("/") &&
                      !router.pathname.startsWith("/us")
                        ? "active"
                        : ""
                    }
                  >
                    <UKFlag />
                  </DropdownItem>
                  <DropdownItem
                    href="/us"
                    title={`United States`}
                    aria-label={`United States`}
                    className={
                      router.pathname.startsWith("/us") ? "active" : ""
                    }
                  >
                    <USFlag />
                  </DropdownItem>
                  {/* <DropdownItem
                                        href="https://www.lorienglobal.de/"
                                        title={`Germany`}
                                        className={
                                            router.pathname.startsWith("/au") &&
                                                !router.pathname.startsWith("/us")
                                                ? "active"
                                                : ""
                                        }
                                    >
                                        <DEFlag />
                                    </DropdownItem> */}
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
            <div className="d-flex align-items-center d-md-flex">
              <button
                className="search-icon d-flex d-none d-lg-inline-flex"
                aria-label="Search"
                onClick={toggleSearch}
              >
                <SearchIcon />
              </button>
              <Link
                href={`${
                  router.pathname.startsWith("/us")
                    ? "/us/job-seekers#join-our-talent-network"
                    : "/job-seekers#join-our-talent-network"
                }`}
              >
                <a className="primaryBtn no-arrow text-nowrap m-0 d-none d-lg-inline-block ms-2">
                  Join Our Talent Network
                </a>
              </Link>
              {/* <Link
                                href={`${router.pathname.startsWith("/us")
                                    ? "/us/contact-us"
                                    : "/contact-us"
                                    }`}>
                                <a className="secondaryBtn no-arrow text-nowrap m-0 d-none d-lg-inline-block ms-2">Contact Us</a>
                            </Link> */}
              <Link
                href={`${
                  router.pathname.startsWith("/us")
                    ? "/us/contact-us"
                    : "/contact-us"
                }`}
              >
                <a
                  className="secondaryBtn no-arrow text-nowrap m-0 ms-2"
                  aria-label="Contact Us"
                >
                  <span className="telephone-icon me-lg-2">
                    <Telephone />
                  </span>
                  <span className="d-none d-lg-inline-block">Contact Us</span>
                </a>
              </Link>
              <button
                aria-label="Menu"
                className={`d-inline-block d-lg-none mobile-menu ms-2 position-relative ${
                  isOpen ? "active" : ""
                }`}
                onClick={() => setIsOpen((isOpen) => !isOpen)}
              >
                Menu
              </button>
            </div>
          </div>
          {isAboutDropdownOpen && (
            <div className="dropdown-navigations position-absolute py-4 px-5 w-100 mt-1">
              <Row>
                <Col md={3} className="pe-md-5 mb-4 mb-md-0">
                  {/* <p className="mb-0 fs-6 primary-color"><small>Overview</small></p> */}
                  <Nav className="list-unstyled">
                    <NavItem
                      className="w-100 mb-2"
                      onClick={() => closeAllDropdowns()}
                    >
                      <Link
                        href={`${
                          router.pathname.startsWith("/us") ? "/us/" : ""
                        }/about-us/`}
                      >
                        <a>
                          <strong>About Us</strong>
                        </a>
                      </Link>
                    </NavItem>
                    <NavItem
                      className="w-100 mb-2"
                      onClick={() => closeAllDropdowns()}
                    >
                      <Link
                        href={`${
                          router.pathname.startsWith("/us") ? "/us/" : ""
                        }/about-us/meet-the-team/`}
                      >
                        <a>
                          <strong>Meet the team</strong>
                        </a>
                      </Link>
                    </NavItem>
                    {router.pathname.startsWith("/us") ? (
                      ""
                    ) : (
                      <Link href="/about-us/csr-partnerships/">
                        <a>
                          <strong>CSR & Partnerships</strong>
                        </a>
                      </Link>
                    )}
                    {/* <Link href={`${router.pathname.startsWith("/us") ? "/us/" : ""}/about-us/csr-partnerships/`}>
                                                 <a>
                                                    <strong>CSR & Partnerships</strong>
                                                </a>
                                            </Link> */}
                    <NavItem
                      className="w-100 mb-2"
                      onClick={() => closeAllDropdowns()}
                    >
                      <Link
                        href={`${
                          router.pathname.startsWith("/us") ? "/us/" : ""
                        }/about-us/join-us/`}
                      >
                        <a>
                          <strong>Join Our Team</strong>
                        </a>
                      </Link>
                    </NavItem>
                    <NavItem
                      className="w-100 mb-2"
                      onClick={() => closeAllDropdowns()}
                    >
                      <Link
                        href={`${
                          router.pathname.startsWith("/us") ? "/us/" : ""
                        }/about-us/refer-a-friend/`}
                      >
                        <a>
                          <strong>Refer A Friend</strong>
                        </a>
                      </Link>
                    </NavItem>
                    <NavItem
                      className="w-100 mb-2"
                      onClick={() => closeAllDropdowns()}
                    >
                      {router.pathname.startsWith("/us") ? (
                        ""
                      ) : (
                        <Link
                          href={`${
                            router.pathname.startsWith("/us") ? "/us/" : ""
                          }/about-us/srg-scotland/`}
                        >
                          <a>
                            <strong>SRG Scotland</strong>
                          </a>
                        </Link>
                      )}
                    </NavItem>
                    <NavItem
                      className="w-100 mb-2"
                      onClick={() => closeAllDropdowns()}
                    >
                      {router.pathname.startsWith("/us") ? (
                        ""
                      ) : (
                        <Link
                          href={`${
                            router.pathname.startsWith("/us") ? "/us/" : ""
                          }/about-us/srg-ireland/`}
                        >
                          <a>
                            <strong>SRG Ireland</strong>
                          </a>
                        </Link>
                      )}
                    </NavItem>
                  </Nav>
                </Col>
                {/* <Col md={9}>
                                    <p className="mb-0 fs-6 primary-color"><small>Discover the solutions</small></p>
                                    <Nav className="list-unstyled">
                                        {SubMenuNavigations.category_values.map((post, index) => (
                                            post.parent.en === "About" ?
                                                <NavItem key={index} className="w-100 mb-2">
                                                    <Link href={`/solutions/${post.url_slug}/`}>
                                                        <a>
                                                            <strong>{post.title.en}</strong>
                                                        </a>
                                                    </Link>
                                                </NavItem> : ""
                                        ))}
                                    </Nav>
                                </Col> */}
              </Row>
            </div>
          )}
          {isInsightsDropdownOpen && (
            <div className="dropdown-navigations position-absolute py-4 px-5 w-100 mt-1">
              <Row>
                <Col md={4} className="pe-md-5 mb-4 mb-md-0">
                  {/* <p className="mb-0 fs-6 primary-color"><small>Overview</small></p> */}
                  <Nav className="list-unstyled">
                    <NavItem
                      className="w-100 mb-2"
                      onClick={() => closeAllDropdowns()}
                    >
                      <Link
                        href={`${
                          router.pathname.startsWith("/us") ? "/us/" : ""
                        }/blog/`}
                      >
                        <a>
                          <strong>News & Insights</strong>
                        </a>
                      </Link>
                    </NavItem>
                    <NavItem
                      className="w-100 mb-2"
                      onClick={() => closeAllDropdowns()}
                    >
                      <Link
                        href={`${
                          router.pathname.startsWith("/us")
                            ? "/us/blog/tag/events"
                            : "/blog/tag/events"
                        }`}
                      >
                        <a>
                          <strong>Events</strong>
                        </a>
                      </Link>
                    </NavItem>
                    <NavItem
                      className="w-100 mb-2"
                      onClick={() => closeAllDropdowns()}
                    >
                      <Link
                        href={`${
                          router.pathname.startsWith("/us") ? "/us/" : ""
                        }/blog/tag/guides-and-reports/`}
                      >
                        <a>
                          <strong>Guides & Reports</strong>
                        </a>
                      </Link>
                    </NavItem>
                    <NavItem
                      className="w-100 mb-2"
                      onClick={() => closeAllDropdowns()}
                    >
                      <Link
                        href={`${
                          router.pathname.startsWith("/us") ? "/us/" : ""
                        }/podcasts`}
                      >
                        <a>
                          <strong>Podcasts & Webinars</strong>
                        </a>
                      </Link>
                    </NavItem>
                    {!router.pathname.startsWith("/us") && (
                      <NavItem
                        className="w-100 mb-2"
                        onClick={() => closeAllDropdowns()}
                      >
                          <Link href="/blog/tag/career-advice">
                            <a>
                              <strong>
                              Career Advice
                              </strong>
                            </a>
                          </Link>
                      </NavItem>
                    )}
                    {!router.pathname.startsWith("/us") && (
                      <NavItem
                        className="w-100 mb-2"
                        onClick={() => closeAllDropdowns()}
                      >
                        <Link href="/blog/tag/case-study/">
                            <a>
                              <strong>
                              Case Studies
                              </strong>
                            </a>
                          </Link>
                      </NavItem>
                    )}
                    <NavItem
                      className="w-100 mb"
                      onClick={() => closeAllDropdowns()}
                    >
                      <Link
                        href={`${
                          router.pathname.startsWith("/us")
                            ? "/us/salary-survey"
                            : "/global-talent-trends-and-insights-2024/"
                        }`}
                      >
                        <a>
                          <strong>{`${
                            router.pathname.startsWith("/us")
                              ? "Salary Survey"
                              : "Global Talent Trends and Insights 2024"
                          }`}</strong>
                        </a>
                      </Link>
                    </NavItem>
                  </Nav>
                </Col>
                {/* <Col md={3} className="pe-md-5 mb-4 mb-md-0">
                                    <p className="mb-0 fs-6 primary-color"><small>Discover the insights</small></p>
                                    <Nav className="list-unstyled">
                                        <NavItem className="w-100 mb-2" onClick={() => closeAllDropdowns()}>
                                            <Link href="/insights/success-story/">
                                                <a>
                                                    <strong>Success story</strong>
                                                </a>
                                            </Link>
                                        </NavItem>
                                    </Nav>
                                </Col> */}
              </Row>
            </div>
          )}
          {isSolutionssDropdownOpen && (
            <div className="dropdown-navigations position-absolute py-4 px-5 w-100 mt-1">
              <Row>
                <Col md={3} className="pe-md-5 mb-4 mb-md-0">
                  {/* <p className="mb-0 fs-6 primary-color"><small>Overview</small></p> */}
                  <Nav className="list-unstyled">
                    {SolutionsNavigation.category_values.map((post, index) => (
                      <NavItem
                        key={index}
                        className="w-100 mb-2"
                        onClick={() => closeAllDropdowns()}
                      >
                        <Link
                          href={`${
                            router.pathname.startsWith("/us") ? "/us/" : ""
                          }/solutions/${post.url_slug}/`}
                        >
                          <a>
                            <strong>{post.title.en}</strong>
                          </a>
                        </Link>
                      </NavItem>
                    ))}
                  </Nav>
                </Col>
              </Row>
            </div>
          )}
          {isClientsDropdownOpen && (
            <div className="dropdown-navigations position-absolute py-4 px-5 w-100 mt-1">
              <Row>
                <Col md={3} className="pe-md-5 mb-4 mb-md-0">
                  {/* <p className="mb-0 fs-6 primary-color"><small>Overview</small></p> */}
                  <Nav className="list-unstyled">
                    <NavItem
                      className="w-100 mb-2"
                      onClick={() => closeAllDropdowns()}
                    >
                      <Link
                        href={`${
                          router.pathname.startsWith("/us") ? "/us/" : ""
                        }/business-solutions/`}
                      >
                        <a>
                          <strong>Business Solutions</strong>
                        </a>
                      </Link>
                    </NavItem>
                    {!router.pathname.startsWith("/us") &&
                      <NavItem
                        className="w-100 mb-2"
                        onClick={() => closeAllDropdowns()}
                      >
                        <Link
                          href={`${
                            router.pathname.startsWith("/us") ? "/us/" : ""
                          }/synergy-scientific-solutions/`}
                        >
                          <a>
                            <strong>Synergy Scientific Solutions</strong>
                          </a>
                        </Link>
                      </NavItem>
                    }
                             {!router.pathname.startsWith("/us") &&
                      <NavItem
                        className="w-100 mb-2"
                        onClick={() => closeAllDropdowns()}
                      >
                        <Link
                          href={`${
                            router.pathname.startsWith("/us") ? "/us/" : ""
                          }/commercial-services/`}
                        >
                          <a>
                            <strong>Commercial Services</strong>
                          </a>
                        </Link>
                      </NavItem>
                    }
                  </Nav>
                </Col>
                <Col md={3} className="pe-md-5 mb-4 mb-md-0">
                  {/* <p className="mb-0 fs-6 primary-color"><small>Solutions</small></p> */}
                  {/* <Nav className="list-unstyled">
                                        <NavItem className="w-100 mb-2" onClick={() => closeAllDropdowns()}>
                                            <Link href="/insights/success-story/">
                                                <a>
                                                    <strong>Success story</strong>
                                                </a>
                                            </Link>
                                        </NavItem>
                                    </Nav> */}

                  <Nav className="list-unstyled">
                    {router.pathname.startsWith("/us")
                      ? SolutionsNavigationUS.category_values.map(
                          (post, index) => (
                            <NavItem
                              key={index}
                              className="w-100 mb-2"
                              onClick={() => closeAllDropdowns()}
                            >
                              <Link
                                href={`${
                                  router.pathname.startsWith("/us")
                                    ? "/us/"
                                    : ""
                                }/business-solutions/${post.url_slug}/`}
                              >
                                <a>
                                  <strong>{post.title.en}</strong>
                                </a>
                              </Link>
                            </NavItem>
                          )
                        )
                      : SolutionsNavigation.category_values.filter((hide => !hide.hidden_in_menu?.en === true)).map(
                          (post, index) => (
                            <NavItem
                              key={index}
                              className="w-100 mb-2"
                              onClick={() => closeAllDropdowns()}
                            >
                              <Link
                                href={`${
                                  router.pathname.startsWith("/us")
                                    ? "/us/"
                                    : ""
                                }/business-solutions/${post.url_slug}/`}
                              >
                                <a>
                                  <strong>{post.title.en}</strong>
                                </a>
                              </Link>
                            </NavItem>
                          )
                        )}
                  </Nav>
                </Col>
              </Row>
            </div>
          )}
          {isContractorsDropdownOpen && (
            <div className="dropdown-navigations position-absolute py-4 px-5 w-100 mt-1">
              <Row>
                <Col md={3} className="pe-md-5 mb-4 mb-md-0">
                  {/* <p className="mb-0 fs-6 primary-color"><small>Overview</small></p> */}
                  <Nav className="list-unstyled">
                    <NavItem
                      className="w-100 mb-2"
                      onClick={() => closeAllDropdowns()}
                    >
                      <Link
                        href={`${
                          router.pathname.startsWith("/us") ? "/us/" : ""
                        }/contractors/`}
                      >
                        <a>
                          <strong>Contractors</strong>
                        </a>
                      </Link>
                    </NavItem>
                  </Nav>
                </Col>
              </Row>
            </div>
          )}
          {isJobSeekersDropdownOpen && (
            <div className="dropdown-navigations position-absolute py-4 px-5 w-100 mt-1">
              <Row>
                <Col md={3} className="pe-md-5 mb-4 mb-md-0">
                  {/* <p className="mb-0 fs-6 primary-color"><small>Overview</small></p> */}
                  <Nav className="list-unstyled">
                    {!router.pathname.startsWith("/us") && (
                      <NavItem
                        className="w-100 mb-2"
                        onClick={() => closeAllDropdowns()}
                      >
                        <Link
                          href={`${
                            router.pathname.startsWith("/us") ? "/us/" : ""
                          }/job-seekers/`}
                        >
                          <a>
                            <strong>Job Seekers</strong>
                          </a>
                        </Link>
                      </NavItem>
                    )}
                    <NavItem
                      className="w-100 mb-2"
                      onClick={() => closeAllDropdowns()}
                    >
                      <Link
                        href={`${
                          router.pathname.startsWith("/us") ? "/us/" : ""
                        }/jobs/`}
                      >
                        <a>
                          <strong>Job Search</strong>
                        </a>
                      </Link>
                    </NavItem>
                    <NavItem
                      className="w-100 mb-2"
                      onClick={() => closeAllDropdowns()}
                    >
                      <Link
                        href={`/graduates`}
                      >
                        <a>
                          <strong>Graduates</strong>
                        </a>
                      </Link>
                    </NavItem>
                    {/* <NavItem className="w-100 mb-2" onClick={() => closeAllDropdowns()}>
                                            <Link href={`${router.pathname.startsWith("/us") ? "/us/" : ""}/job-seekers/featured-employers/`}>
                                                <a>
                                                    <strong>Featured Employers</strong>
                                                </a>
                                            </Link>
                                        </NavItem> */}
                    {/*<NavItem className="w-100 mb-2" onClick={() => closeAllDropdowns()}>
                                            <Link href={`${router.pathname.startsWith("/us") ? "/us/" : ""}/contractors/`}>
                                                <a>
                                                    <strong>Contractors</strong>
                                                </a>
                                            </Link>
                                        </NavItem>
                                        <NavItem className="w-100 mb-2" onClick={() => closeAllDropdowns()}>
                                            <Link href={`${router.pathname.startsWith("/us") ? "/us/" : ""}/insights/`}>
                                                <a>
                                                    <strong>Insights</strong>
                                                </a>
                                            </Link>
                                        </NavItem>
                                        <NavItem className="w-100 mb-2" onClick={() => closeAllDropdowns()}>
                                            <Link href={`${router.pathname.startsWith("/us") ? "/us/" : ""}/about-us/`}>
                                                <a>
                                                    <strong>About Us</strong>
                                                </a>
                                            </Link>
                                        </NavItem> */}
                  </Nav>
                </Col>
              </Row>
            </div>
          )}
        </Container>
      </section>
      <div
        className={`${styles.root} mobile-dropdown ${
          isOpen ? "active" : "overflow-hidden"
        } d-inline-block d-lg-none`}
      >
        <Container>
          <div className="d-flex align-items-center mb-4 mobile-search">
            <div className="w-100 position-relative filters">
              <input
                type="text"
                id="search"
                placeholder="Site Search..."
                onChange={handleSearchChange}
                value={searchQuery}
              />
              <span className="icon d-inline-block position-absolute">
                <SearchIcon />
              </span>
              {searchResults.length > 0 && (
                <div className="mt-4 search-results">
                  <p className="fs-4">
                    <strong>Search Results:</strong>
                  </p>
                  <ul>
                    {searchResults.map((post, index) => (
                      <li key={index}>
                        <Link href={`/blog/${post.item.url_slug}`}>
                          <a onClick={() => setIsOpen((isOpen) => !isOpen)}>
                            {post.item.title.en}
                          </a>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </Container>
        <div className="d-flex flex-wrap justify-content-between h-100 mobileWrapper">
          <div className="w-100">
            <Nav className="list-unstyled">
              {MainNavigation?.category_values.map((post, index) => (
                <NavItem
                  key={index}
                  className={`w-100 position-relative parent ${
                    post.has_children.en ? "has-children" : null
                  }`}
                  onClick={() => closeAllDropdowns()}
                >
                  {post.has_children.en ? (
                    <>
                      {/* <strong>{post.title.en}</strong> */}
                      <Accordion open={open} toggle={toggle}>

                        {post.title.en == "Job Seekers" && (
                          <AccordionItem>
                            <AccordionHeader targetId={`${index}`}>
                              {post.title.en}
                            </AccordionHeader>
                            <AccordionBody accordionId={`${index}`}>
                              <p>
                                {" "}
                                <Link href="/job-seekers/">
                                  <a
                                    onClick={() =>
                                      setIsOpen((isOpen) => !isOpen)
                                    }
                                  >
                                    Job Seekers
                                  </a>
                                </Link>
                              </p>
                              <p>
                                {" "}
                                <Link href="/jobs/">
                                  <a
                                    onClick={() =>
                                      setIsOpen((isOpen) => !isOpen)
                                    }
                                  >
                                    Job Search
                                  </a>
                                </Link>
                              </p>
                              {/* <p> <Link href="/job-seekers/featured-employers/">
                                                                <a onClick={() => setIsOpen((isOpen) => !isOpen)}>Featured Employers</a>
                                                            </Link></p> */}
                            </AccordionBody>
                          </AccordionItem>
                        )}

                        {post.title.en == "Business Solutions" && (
                          <AccordionItem>
                            <AccordionHeader targetId={`${index}`}>
                              {post.title.en}
                            </AccordionHeader>
                            <AccordionBody accordionId={`${index}`}>
                              <p><Link href="/business-solutions"><a onClick={() => setIsOpen((isOpen) => !isOpen)} className="fw-semibold">Business Solutions</a></Link></p>
                              <p><Link href="/synergy-scientific-solutions"><a onClick={() => setIsOpen((isOpen) => !isOpen)}>Synergy Scientific Solutions</a></Link></p>
                              <p><Link href="/sommercial-services"><a onClick={() => setIsOpen((isOpen) => !isOpen)}>Commercial Services</a></Link></p>
                              <br/>
                              <p><Link href="/business-solutions/ad-hoc-staffing-solutions"><a onClick={() => setIsOpen((isOpen) => !isOpen)} className="fw-semibold">Staffing Solutions</a></Link></p>
                              <p><Link href="/business-solutions/executive-search"><a onClick={() => setIsOpen((isOpen) => !isOpen)}>Executive & Technical Search</a></Link></p>
                              <p><Link href="/business-solutions/managed-service-provider-contingent-workforce-solutions"><a onClick={() => setIsOpen((isOpen) => !isOpen)}>Managed Service Provider & Contingent Workforce Solutions</a></Link></p>
                              <p><Link href="/business-solutions/pre-clinical-discovery-science"><a onClick={() => setIsOpen((isOpen) => !isOpen)}>Pre-Clinical Discovery Science</a></Link></p>
                              <p><Link href="/business-solutions/early-talent"><a onClick={() => setIsOpen((isOpen) => !isOpen)}>Early Talent in STEM</a></Link></p>
                              <p><Link href="/business-solutions/recruitment-process-outsourcing-and-permanent-workforce-solutions"><a onClick={() => setIsOpen((isOpen) => !isOpen)}>Recruitment Process Outsourcing and Permanent Workforce Solutions</a></Link></p>
                              <p><Link href="/business-solutions/statement-of-work"><a onClick={() => setIsOpen((isOpen) => !isOpen)}>Statement of Work (SOW)</a></Link></p>
                              <p><Link href="/business-solutions/salary-benchmarking"><a onClick={() => setIsOpen((isOpen) => !isOpen)}>Salary Benchmarking</a></Link></p>
                            </AccordionBody>
                          </AccordionItem>
                        )}
                        
                        {post.title.en == "Contractors" && (
                          <AccordionItem>
                            <AccordionHeader targetId={`${index}`}>
                              {post.title.en}
                            </AccordionHeader>
                            <AccordionBody accordionId={`${index}`}>
                              <Link href="/contractors/">
                                <a
                                  onClick={() => setIsOpen((isOpen) => !isOpen)}
                                >
                                  {post.title.en}
                                </a>
                              </Link>
                            </AccordionBody>
                          </AccordionItem>
                        )}
                        
                        {post.title.en == "Insights" && (
                          <AccordionItem>
                            <AccordionHeader targetId={`${index}`}>
                              {post.title.en}
                            </AccordionHeader>
                            <AccordionBody accordionId={`${index}`}>
                              <p><Link href="/blog"><a onClick={() => setIsOpen((isOpen) => !isOpen)}>News & Insights</a></Link></p>
                              <p><Link href="/blog/tag/events"><a onClick={() => setIsOpen((isOpen) => !isOpen)}>Events</a></Link></p>
                              <p><Link href="/blog/tag/guides-and-reports"><a onClick={() => setIsOpen((isOpen) => !isOpen)}>Guides & Reports</a></Link></p>
                              <p><Link href="/podcasts"><a onClick={() => setIsOpen((isOpen) => !isOpen)}>Podcasts & Webinars</a></Link></p>
                              <p><Link href="/blog/tag/career-advice"><a onClick={() => setIsOpen((isOpen) => !isOpen)}>Career Advice</a></Link></p>
                              <p><Link href="/blog/tag/case-study"><a onClick={() => setIsOpen((isOpen) => !isOpen)}>Case Study</a></Link></p>
                              <p><Link href="/global-talent-trends-and-insights-2024"><a onClick={() => setIsOpen((isOpen) => !isOpen)}>Global Talent Trends and Insights 2024</a></Link></p>
                            </AccordionBody>
                          </AccordionItem>
                        )}

                        {post.title.en == "About Us" && (
                          <AccordionItem>
                            <AccordionHeader targetId={`${index}`}>
                              {post.title.en}
                            </AccordionHeader>
                            <AccordionBody accordionId={`${index}`}>
                              <p>
                                <Link href="/about-us/">
                                  <a
                                    onClick={() =>
                                      setIsOpen((isOpen) => !isOpen)
                                    }
                                  >
                                    About us
                                  </a>
                                </Link>
                              </p>
                              <p>
                                <Link href="/about-us/meet-the-team/">
                                  <a
                                    onClick={() =>
                                      setIsOpen((isOpen) => !isOpen)
                                    }
                                  >
                                    Meet the team
                                  </a>
                                </Link>
                              </p>
                              {router.pathname.startsWith("/us") ? (
                                ""
                              ) : (
                                <p>
                                  <Link href="/about-us/csr-partnerships/">
                                    <a
                                      onClick={() =>
                                        setIsOpen((isOpen) => !isOpen)
                                      }
                                    >
                                      CSR & Partnerships
                                    </a>
                                  </Link>
                                </p>
                              )}

                              <p>
                                <Link href="/about-us/join-us/">
                                  <a
                                    onClick={() =>
                                      setIsOpen((isOpen) => !isOpen)
                                    }
                                  >
                                    Join us
                                  </a>
                                </Link>
                              </p>
                              <p>
                                <Link href="/about-us/refer-a-friend/">
                                  <a
                                    onClick={() =>
                                      setIsOpen((isOpen) => !isOpen)
                                    }
                                  >
                                    Refer A Friend
                                  </a>
                                </Link>
                              </p>
                            </AccordionBody>
                          </AccordionItem>
                        )}

                      </Accordion>{" "}
                    </>
                  ) : (
                    <Link href={`${post.link.en}`}>
                      <a
                        onClick={() => setIsOpen((isOpen) => !isOpen)}
                        className="d-block"
                      >
                        <strong>{post.title.en}</strong>
                      </a>
                    </Link>
                  )}
                </NavItem>
              ))}
            </Nav>
          </div>
          <div className="mobile-language w-100 d-flex align-items-end">
            <div className="w-100 mb-4">
              <hr></hr>
              <Container>
                <UncontrolledDropdown className="lang_select">
                  <DropdownToggle>
                    <span
                      className={
                        router.pathname.startsWith("/") &&
                        !router.pathname.startsWith("/us")
                          ? "active"
                          : ""
                      }
                    >
                      <UKFlag /> UK
                    </span>
                    <span
                      className={
                        router.pathname.startsWith("/us") ? "active" : ""
                      }
                    >
                      <USFlag /> US
                    </span>
                    {/* <span
                                            className={
                                                router.pathname.startsWith("/au") &&
                                                    !router.pathname.startsWith("/us")
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <DEFlag /> DE
                                        </span> */}
                  </DropdownToggle>

                  <DropdownMenu aria-labelledby="dropdownMenuButton">
                    <DropdownItem
                      href="/"
                      title={`United Kingdom`}
                      className={
                        router.pathname.startsWith("/") &&
                        !router.pathname.startsWith("/us")
                          ? "active"
                          : ""
                      }
                    >
                      <UKFlag /> UK
                    </DropdownItem>
                    <DropdownItem
                      href="/us/"
                      title={`United States`}
                      className={
                        router.pathname.startsWith("/us") ? "active" : ""
                      }
                    >
                      <USFlag /> US
                    </DropdownItem>
                    {/* <DropdownItem
                                            href="https://www.lorienglobal.de/"
                                            title={`Germany`}
                                            className={
                                                router.pathname.startsWith("/au") &&
                                                    !router.pathname.startsWith("/us")
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <DEFlag /> DE
                                        </DropdownItem> */}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Container>
            </div>
          </div>
        </div>
      </div>
      {isSearchOpen && (
        <section className={`${styles.root} site-search d-none d-md-block`}>
          <Container>
            <div className="d-flex align-items-center">
              <div className="w-100 position-relative filters">
                <input
                  type="text"
                  id="search"
                  placeholder="Site Search..."
                  // onChange={handleSearchChange}
                  // value={searchValue}
                  onChange={handleSearchChange}
                  value={searchQuery}
                />
                <span className="icon d-inline-block position-absolute">
                  <SearchIcon />
                </span>
              </div>
              <button
                className={`primaryBtn no-arrow border-0 m-0 ms-3`}
                aria-label="Search"
                onClick={() => toggleSearch()}
              >
                <CloseMark />
              </button>
            </div>
            {searchResults.length > 0 && (
              <div className="mt-4 search-results">
                <p className="fs-4">
                  <strong>Search Results:</strong>
                </p>
                <ul>
                  {searchResults.map((post, index) => (
                    <li key={index}>
                       <Link href={`${router.pathname.startsWith("/us") ? "/us" : ""}/blog/${post.item.url_slug}`}>
                        <a onClick={() => toggleSearch()}>
                          {post.item.title.en}
                        </a>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </Container>
        </section>
      )}
    </>
  );
}
